import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { compose, withHandlers } from 'recompose';
import Parapgraph from '../../../components/Typography/Paragraph';
import stores from '../../../stores';
import { withListStore } from "../../../utils/hocs";
import { TableContainer, Tbody, Td, Th, Thead, Tr, TrTop } from '../../Customers/Table/TableStyled';
import { ButtonDelete, TdButton } from '../../CustomLists/Table/TableStyled';
import { ParagraphWrapper } from '../CustomListsDetailsStyled';

const EntitiesTable = ({listId, list, hasMoreItems, loadMoreItems, removeEntity, rowsCount}) => {
    const { t } = useTranslation();
    return (
        <>
          {list && list.length > 0 ? (
            <ParagraphWrapper>
              <Parapgraph>{t('customLists.totalListRows', {count: rowsCount})}</Parapgraph>
            </ParagraphWrapper>
          ) : null}
          <InfiniteScroll
            // initialLoad={true}
            loadMore={(page) => loadMoreItems(page, {listId})}
            hasMore={hasMoreItems}
            style={{ overflow: 'auto' }}
          >
            {list && list.length > 0 ? (
              <TableContainer>
                <Thead>
                  <TrTop>
                    <Th>
                      {t('customLists.entitiesTableHeadingName')}
                    </Th>
                    <Th>
                      {t('customLists.entitiesTableHeadingRegistrationDate')}
                    </Th>
                    <Th>
                      {t('customLists.entitiesTableHeadingAddedAt')}
                    </Th>
                    <Th/>
                  </TrTop>
                </Thead>
                <Tbody>
                  {list.map(item => {
                      const createdAt = moment(item.created_at).format('DD.MM.YYYY');
                      const registrationDate = moment(item.registration_date).format('DD.MM.YYYY');
                      return (
                        <Tr key={item.ogrn}>
                          <Td>
                            <p style={{marginBottom: 5}}>{item.name_full}</p>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              title={t('inquiries.openOnRBC')}
                              href={`https://companies.rbc.ru/id/${item.ogrn}-slug`}
                            >
                              {item.inn}&nbsp;/&nbsp;{item.ogrn}
                            </a>
                          </Td>
                          <Td>{registrationDate}</Td>
                          <Td>{createdAt}</Td>
                          <TdButton>
                            <Tooltip title={t('customLists.tooltipDeleteItem')} placement="top" arrow>
                              <ButtonDelete onClick={e => removeEntity(e, item.ogrn)}>
                                  delete
                              </ButtonDelete>
                            </Tooltip>
                          </TdButton>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableContainer>
            ) : (
              <h3 style={{fontWeight: '400'}}>В списке нет ни одного элемента</h3>
            )}
          </InfiniteScroll>
        </>
    )
}

const enhance = compose(
    withListStore('CustomListEntitiesStore'),
    withHandlers(() => ({
      removeEntity: ({ listId }) => async (e, ogrn) => {
          e.preventDefault();
          stores['CustomListEntitiesStore'].remove({ listId, ogrn })
      }
  }))
);

export default enhance(EntitiesTable)