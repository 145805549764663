export class AbstractListStore {
    constructor() {
        this.reset()
    }

    reset() {
        this.list = []
        this.hasMoreItems = true
        this.rowsCount = 0
    }

    async loadMoreItems(page, params) {
        const loadParams = typeof params === 'object' ? {...params, page} : {page}
        // .load() must be implemented in child class
        const r = await this.load(loadParams)
        const { data, meta } = r
        const { total_pages, total_items } = meta;
        this.rowsCount = total_items
        const list = [...this.list, ...data]
        // .areItemsEqual() must be implemented in child class
        this.list = list.filter((item, i) => i === list.findIndex(next => this.areItemsEqual(item, next)))
        this.hasMoreItems = page < total_pages
    }
}