import styled from 'styled-components';
import { COLOR_BLACK_TEXT } from '../../../constants/colors';
import { ICON_DELETE, ICON_PLUS, ICON_SEARCH } from '../../../constants/icons';

export const Wrapper = styled.div``;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
`;
export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
`;
export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: ${COLOR_BLACK_TEXT};
  text-align: left;
  font-family: Lato;
  font-weight: 600;
  padding-bottom: 11px;
  &.active {
    color: #109cf1;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Td = styled.td`
  color: ${COLOR_BLACK_TEXT};
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  padding-right: 15px;
`;

export const TdButton = styled(Td)`
  width: 28px;
  padding-right: 5px;
`;

export const ButtonEdit = styled.button`
  font-size: 0;
  background: url(${ICON_PLUS}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: .5;
`;

export const ButtonDelete = styled.button`
  font-size: 0;
  background: url(${ICON_DELETE}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ButtonSearch = styled.button`
  font-size: 0;
  background: url(${ICON_SEARCH}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: .5;
`;

export const Button = styled.button`
  color: #06739a;
  font-weight: 900;
  font-size: 12px;
  font-family: Lato;
  line-height: 14px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;
