import { decorate, observable } from 'mobx';
import api from '../api';

export default class CustomListsStore {
    lists = []
    isBusy = false
    isDeleting = false
    isAddingEntries = false
    isAddingList = false
    hasMoreItems = true
    selectedItem = null

    constructor(customListPersonsStore, customListEntitiesStore) {
        this.customListPersonsStore = customListPersonsStore
        this.customListEntitiesStore = customListEntitiesStore
    }

    async fetchCustomLists({size, offset}) {
        if (this.isBusy) {
            return;
        }
        this.isBusy = true;
        try {
            const page = Math.floor(offset / size) + 1
            const { data, meta } = await api.customLists.find({size, page})
            
            data.forEach(item => {
                item.id = +item.id
            })
            if (page === 1) {
                this.lists = data;
            } else {
                this.addCustomLists(...data);
            }
            this.hasMoreItems = meta.page < meta.total_pages
            return data;
        } finally {
            this.isBusy = false
        }
        
    }

    async fetchOne({listId}) {
        if (this.isBusy) {
            return;
        }
        this.isBusy = true;
        try {
            this.selectedItem = await api.customLists.findOne(listId)
            return this.selectedItem
        } finally {
            this.isBusy = false
        }
    }

    async removeList(id) {
        if (this.isDeleting) {
            return false;
        }
        this.isDeleting = true;
        try {
            await api.customLists.deleteById(id);
            this.lists = [...this.lists].filter(x => x.id !== id);
            return true;
        } finally {
            this.isDeleting = false;
        }
    }

    async addList(data) {
        if (this.isAddingList) {
            return
        }
        this.isAddingList = true
        try {
            const list = await api.customLists.addList(data)
            if (list.code) {
                throw new Error(list.code)
            }
            this.addCustomLists(list)
        } finally {
            this.isAddingList = false
        }
    }

    async addEntries(id, data) {
        if (this.isAddingEntries) {
            return
        }
        this.isAddingEntries = true
        try {
            const response = await api.customLists.addEntries(id, data)
            return {
                persons: response.find(x => x.type === 'person')?.count || 0,
                entities: response.find(x => x.type === 'entity')?.count || 0
            }
        } finally {
            this.isAddingEntries = false
        }
    }


    /**
     * @private
     * @param  {...any} items
     */
    addCustomLists(...items) {
        const list = [...items, ...this.lists]; 
        this.lists = list
            .filter((item, i) => i === list.findIndex(next => next.id === item.id))
            .sort((a, b) => b.id - a.id)
    }
}

decorate(CustomListsStore, {
    lists: observable,
    hasMoreItems: observable,
    selectedItem: observable,
});