import api from "../api";
import { AbstractListStore, decorateAbstractListStore } from "./base";

const PAGE_SIZE = 50

export default class CustomListEntitiesStore extends AbstractListStore {
    reset() {
        api.customLists.break()
        api.customLists.reset()
        super.reset()
    }

    load({ listId, page }) {
        return api.customLists.findEntities({listId, size: PAGE_SIZE, page})
    }

    areItemsEqual(a, b) {
        return a.ogrn === b.ogrn
    }

    async remove({ listId, ogrn }) {
        await api.customLists.deleteEntity(listId, ogrn)
        this.list = this.list.filter(x => x.ogrn !== ogrn)
        // await this.reload({ listId })
    }

    async reload({ listId }) {
        const pagesToReload = Math.max(0, Math.ceil((this.list.length - 1) / PAGE_SIZE))
        this.list = []
        const promises = new Array(pagesToReload).fill(0).map((_, i) => this.load({listId, page: i + 1}))
        await Promise.all(promises)
    }
}

decorateAbstractListStore(CustomListEntitiesStore)