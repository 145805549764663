import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { compose, withHandlers } from 'recompose';
import Parapgraph from '../../../components/Typography/Paragraph';
import stores from '../../../stores';
import { withListStore } from "../../../utils/hocs";
import { TableContainer, Tbody, Td, Th, Thead, Tr, TrTop } from '../../Customers/Table/TableStyled';
import { ButtonDelete, TdButton } from '../../CustomLists/Table/TableStyled';
import { ParagraphWrapper } from '../CustomListsDetailsStyled';

const PersonsTable = ({listId, list, hasMoreItems, loadMoreItems, removePerson, rowsCount}) => {
    const { t } = useTranslation();
    return (
        <>
          {list && list.length > 0 ? (
            <ParagraphWrapper>
              <Parapgraph>{t('customLists.totalListRows', {count: rowsCount})}</Parapgraph>
            </ParagraphWrapper>
          ) : null}
          <InfiniteScroll
            // initialLoad={true}
            loadMore={(page) => loadMoreItems(page, {listId})}
            hasMore={hasMoreItems}
            style={{ overflow: 'auto' }}
          >
            {list && list.length > 0 ? (
              <TableContainer>
                <Thead>
                  <TrTop>
                    <Th>
                      {t('customLists.personsTableHeadingName')}
                    </Th>
                    <Th>
                      {t('customLists.personsTableHeadingTin')}
                    </Th>
                    <Th>
                      {t('customLists.personsTableHeadingEnterpeneur')}
                    </Th>
                    <Th>
                      {t('customLists.personsTableHeadingEnterpeneurAddedAt')}
                    </Th>
                    <Th/>
                  </TrTop>
                </Thead>
                <Tbody>
                  {list &&
                    list.map(item => {
                      const fullName = item.surname ? `${item.surname} ${item.first_name}${item.patronymic ? ' ' + item.patronymic : ''}` : '-'
                      const IP = item.ogrnip ? t('customLists.personsTableHeadingEnterpeneurContent', {ogrnip: item.ogrnip, since: moment(item.registration_date).format('DD.MM.YYYY')}) : '-';
                      const createdAt = moment(item.created_at).format('DD.MM.YYYY');
                      return (
                        <Tr key={item.ogrnip + item.inn}>
                          <Td>{fullName}</Td>
                          <Td>{item.inn}</Td>
                          <Td>{IP}</Td>
                          <Td>{createdAt}</Td>
                          <TdButton>
                            <Tooltip title={t('customLists.tooltipDeleteItem')} placement="top" arrow>
                              <ButtonDelete onClick={e => removePerson(e, item.inn, item.ogrnip)}>
                                  delete
                              </ButtonDelete>
                            </Tooltip>
                          </TdButton>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableContainer>
            ) : (
              <h3 style={{fontWeight: '400'}}>В списке нет ни одного элемента</h3>
            )}
          </InfiniteScroll>
        </>
    )
}

const enhance = compose(
    withListStore('CustomListPersonsStore'),
    withHandlers(() => ({
      removePerson: ({ listId }) => async (e, inn, ogrnip) => {
          e.preventDefault();
          stores['CustomListPersonsStore'].remove({ listId, inn, ogrnip })
      }
  }))
);

export default enhance(PersonsTable)