export const COMMON_ERRORS = {
  USER_NOT_FOUND: 'errors.USER_NOT_EXIST',
  WRONG_LOGIN_OR_PASSWORD: 'errors.WRONG_LOGIN_OR_PASSWORD',
  'old password is wrong': 'errors.old_password_is_wrong',
  'email must be an email': 'errors.email_must_be_an_email',
  'phone must be a valid phone number': 'errors.phone_must_be_a_valid_phone_number',
  INN_ALREADY_USED: 'errors.INN_ALREADY_USED',
  LOGIN_ALREADY_USED: 'errors.LOGIN_ALREADY_USED',
  PHONE_ALREADY_USED: 'errors.PHONE_ALREADY_USED',
  OGRN_ALREADY_USED: 'errors.OGRN_ALREADY_USED',
  KPP_ALREADY_USED: 'errors.KPP_ALREADY_USED',
  EMAIL_ALREADY_USED: 'errors.EMAIL_ALREADY_USED',
  EMAIL_SEND_ERROR: 'errors.EMAIL_SEND_ERROR',
  EMAIL_ALREADY_CONFIRMED: 'errors.EMAIL_ALREADY_CONFIRMED',
  PAYMENT_WITHOUT_CONTRACT: 'errors.PAYMENT_WITHOUT_CONTRACT',
  PAYMENT_CONTRACT_ALREADY_SUCCEED: 'errors.PAYMENT_CONTRACT_ALREADY_SUCCEED',
  PAYMENT_NOT_FOUND: 'errors.PAYMENT_NOT_FOUND',
  TARIFF_NOT_FOUND: 'errors.TARIFF_NOT_FOUND',
  ENTITY_NOT_FOUND: 'errors.ENTITY_NOT_FOUND',
  BANKS_ARE_NOT_ALLOWED: 'errors.BANKS_ARE_NOT_ALLOWED',
  NKO_ARE_NOT_ALLOWED: 'errors.NKO_ARE_NOT_ALLOWED',
  INSURANCE_ORGANIZATIONS_ARE_NOT_ALLOWED: 'errors.INSURANCE_ORGANIZATIONS_ARE_NOT_ALLOWED',
  CAN_NOT_BLOCK_YOURSELF: 'errors.CAN_NOT_BLOCK_YOURSELF',

  PROMOCODE_INVALID: 'errors.PROMOCODE_INVALID',
  PROMOCODE_ALREADY_ACTIVATED: 'errors.PROMOCODE_ALREADY_ACTIVATED',

  LIST_WITH_TITLE_ALREADY_EXISTS: 'errors.LIST_WITH_TITLE_ALREADY_EXISTS',
  ENTITY_NOT_EXISTS: 'errors.ENTITY_NOT_EXISTS',
  ACCESS_DENIED: 'errors.ACCESS_DENIED'
};

export const RECOVERY_ERRORS = {
  USER_NOT_FOUND: 'errors.USER_WITH_THIS_EMAIL_NOT_FOUND',
  PASSWORD_RESET_ALREADY_SUCCEED: 'errors.PASSWORD_RESET_ALREADY_SUCCEED',
  SUBSCRIPTIONS_LIMIT_EXCEEDED: 'errors.SUBSCRIPTIONS_LIMIT_EXCEEDED',
  SUBSCRIPTION_ALREADY_EXISTS: 'errors.SUBSCRIPTION_ALREADY_EXISTS',
};
