import styled from 'styled-components';
import { TitleText } from '../../../components/Typography/Title';
import { COLOR_TITLE_BLACK } from '../../../constants/colors';
import { ICON_CARD_CHECK } from '../../../constants/icons';
import { mobileMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  padding: 43px 50px 54px;
`;

export const InputWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 362px;
`;

export const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const FullWidthDiv = styled.div`
  width: 100%;
`

export const TextArea = styled.textarea`
  margin-top: 25px;
  width: 100%;
  min-height: 155px;
  padding: 18px 14px;
  border-radius: 4px;
  border: 1px solid #abb1ba;
`;

export const Title = styled(TitleText)`
  max-width: 415px;
  margin: 0 auto;
  font-size: 32px;
  text-align: center;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 12px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const MethodsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

export const Img = styled.img``;

export const CardMethod = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: #fff;
  justify-content: space-between;
  border: 2px solid #90a0b7;
  width: 161px;
  height: 161px;
  border-radius: 4px;
  padding: 25px 27px;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border: 1px solid #c2cfe0;
    border-radius: 100%;
    top: 9px;
    right: 9px;
  }
  &.active {
    border: 2px solid #109cf1;
    &:before {
      background: url(${ICON_CARD_CHECK});
      border: none;
    }
  }
`;

export const InputWrapperCreate = styled.div`
  position: relative;
  width: 523px;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const ButtonWrapperCreate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    margin-top: 35px;
    align-items: center;
    button {
      margin-top: 20px;
    }
  }
`;

export const Result = styled.div`
  margin-top: 16px
`

export const ResultPlaceholder = styled.div`
  margin-top: 16px
  visibility: hidden;
`