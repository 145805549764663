import { inject } from 'mobx-react';
import React from 'react';
import { useTranslation } from "react-i18next";
// import { Button } from "react-scroll";
import { compose, withHandlers } from "recompose";
import storeNames from '../../stores/storeNames';
import { withBoolState } from '../../utils/hocs';
import { Button, Title, Top, Wrapper } from "./CustomListsStyled";
import AddEntriesPopup from './Popups/AddEntriesPopup';
import AddListPopup from './Popups/AddListPopup';
// import Icons from './Icons';
import Table from './Table/Table';

const CustomLists = ({
  addEntriesPopup,
  toggleAddEntriesPopup,
  addListPopup,
  toggleAddListPopup,
  onAddEntries
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Top>
          <Title as="h2">{t('sidebar.customLists')}</Title>
          <Button onClick={() => toggleAddListPopup()}>
            {t('customLists.popupAddListTitle')}
          </Button>
        </Top>
        <Table onAddEntries={onAddEntries} />
      </Wrapper>
      {addEntriesPopup ? (
        <AddEntriesPopup closePopup={toggleAddEntriesPopup} />
      ) : null}
      {addListPopup ? (
        <AddListPopup closePopup={toggleAddListPopup} />
      ) : null}
      {/* <Icons /> */}
    </>
  )
}

const enhance = compose(
  inject(storeNames.CustomListsStore),
  withBoolState('addEntriesPopup', false),
  withBoolState('addListPopup', false),
  withHandlers({
    onAddEntries: ({CustomListsStore, toggleAddEntriesPopup}) => (item) => {
      CustomListsStore.selectedItem = item;
      toggleAddEntriesPopup()
    }
  })
);

export default enhance(CustomLists);