import React from 'react';
import { inject, observer } from "mobx-react";
import { useTranslation, withTranslation } from "react-i18next";
import { compose, withHandlers, withProps, withState } from "recompose";
import Popup from "../../../components/Popup/Popup";
import { ButtonWrapper, FormWrapper, FullWidthDiv, Title, Wrapper } from "./PopupsStyled";
import storeNames from '../../../stores/storeNames';
import { COLOR_ACTION_BLUE } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import stores from '../../../stores';
import InputField from '../../../components/Inputs/InputField';
import { ButtonDisabled, Text } from '../../Inquiries/RepeatPopup/PopupStyled';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { withBoolState } from '../../../utils/hocs';

const AddListPopup = ({closePopup, onSubmit, isAddingList, title, setTitle, isRiskList, toggleIsRiskList }) => {
    const { t } = useTranslation();
    return (
      <Popup
        width="600px"
        boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
        borderRadius="4px"
        closePopup={closePopup}
      >
        <Wrapper>
          <Title as="h2">{t('customLists.popupAddListTitle')}</Title>
          {/* <div>{selectedItem.title}</div> */}
          <FormWrapper onSubmit={onSubmit}>
            <FullWidthDiv>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="3"
                placeholder={t('customLists.popupAddListPlaceholder')}
                name="title"
                value={title}
                onChange={value => setTitle(value)}
              />
            </FullWidthDiv>
            <FullWidthDiv>
              <CheckBox
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="10"
                  marginRight="12px"
                  justifyContent="flex-start"
                  checked={isRiskList}
                  testID="TEST_CONFIRMED"
                  onChange={() => toggleIsRiskList()}
                >
                  <div>
                    <p style={{fontSize: 14, marginBottom: 8}}>{t('customLists.popupAddListRiskField')}</p>
                    <Text>{t('customLists.popupAddListRiskHint')}</Text>
                  </div>
                  
                </CheckBox>
            </FullWidthDiv>
            <ButtonWrapper>
              <ButtonDisabled
                testID="TEST_BUTTON_ADD"
                width="303px"
                type="submit"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="7"
                sizes={{ margin: '0 20px' }}
                disabled={isAddingList || title.length === 0}
              >
                {t('form.add')}
              </ButtonDisabled>
              <Button
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="6"
                testID="TEST_BUTTON_CANCEL"
                width="303px"
                type="submit"
                textColor={COLOR_ACTION_BLUE}
                boxShadow="none"
                backgroundColor="#fff"
                sizes={{ margin: '0 20px' }}
                disabled={isAddingList}
                onClick={() => closePopup()}
              >
                {t('form.cancel')}
              </Button>
            </ButtonWrapper>
          </FormWrapper>
        </Wrapper>
      </Popup>
    )
}

const enchance = compose(
    inject(storeNames.CustomListsStore),
    observer,
    withTranslation(),
    withState('title', 'setTitle', ''),
    withBoolState('isRiskList', false),
    withProps(({CustomListsStore}) => ({
      isAddingList: CustomListsStore.isAddingList
    })),
    withHandlers({
        onSubmit: ({title, isRiskList, closePopup}) => async (e) => {
          e.preventDefault()
          const data = {
            title,
            isRiskList
          }
          try {
            await stores.CustomListsStore.addList(data)
            closePopup()
          } catch { }
        }
    })
)

export default enchance(AddListPopup)