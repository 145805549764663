import React from 'react';
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
  withStateHandlers,
} from 'recompose';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../constants/colors';
import Button from '../../components/Buttons/Button';
import history from '../../history';
import storeNames from '../../stores/storeNames';
import InputField from '../../components/Inputs/InputField';
import {
  checkIsEmailValid,
  getEmailEqualMessage,
  hasValue,
  toLowerCaseAndTrim,
} from '../../utils/validators';
import { withBoolState, withHandlePressEnter } from '../../utils/hocs';
import {
  AfterCard,
  AfterCardContainer,
  AfterCardHint,
  BottomCard,
  ButtonWrapper,
  ButtonWrapperWithError,
  Card,
  CheckBoxContrainer,
  ErrorTextTransformed,
  Field,
  Key,
  Main,
  Paragraph,
  Text,
  TextLink,
  TitlePage,
  Value,
  Wrapper,
  WrapperContainer,
  WrrapperBox,
} from './RescoreResponseStyled';
import CheckBox from '../../components/CheckBoxes/CheckBox';
import { getUrlObject } from '../../helpers/url';

const RescoreResponse = ({
  entity,
  isBusy,
  email,
  emailAgain,
  setEmail,
  setEmailAgain,
  emailValidation,
  emailAgainValidation,
  formHandler,
  succeededRescore,
  toggleSucceededRescore,
  confirmed,
  toggleConfirmed,
  handlePressEnter,
}) => {
  const { t } = useTranslation();
  if (!entity || isBusy) {
    return <Main />;
  }

  return (
    <Main>
      <Wrapper>
        <WrapperContainer>
          <WrrapperBox>
            <TitlePage>{t('home.title2')}</TitlePage>
            <Paragraph>{t('home.description2')}:</Paragraph>
            <Card>
              <Field>
                <Key>{t('home.fullName')}:</Key>
                <Value> {entity.name_full}</Value>
              </Field>
              <Field>
                <Key>{t('home.tinKpp')}:</Key>
                <Value>
                  {entity.inn} / {entity.kpp}
                </Value>
              </Field>
              <Field>
                <Key>{t('home.ogrn')}:</Key>
                <Value>{entity.ogrn}</Value>
              </Field>
              {entity.registration && (
                <Field>
                  <Key>{t('home.regDate')}:</Key>
                  <Value>{moment(entity.registration_date).format('DD.MM.YYYY')}</Value>
                </Field>
              )}
            </Card>
            {!succeededRescore && (
              <ButtonWrapper>
                <Button
                  maxWidth="calc(50% - 34px)"
                  width="100%"
                  sizes={{ margin: '0 17px' }}
                  textColor={COLOR_ACTION_BLUE}
                  backgroundColor={COLOR_WHITE}
                  onClick={() => history.push('/')}
                >
                  {t('home.no')}
                </Button>
                <Button
                  maxWidth="calc(50% - 34px)"
                  width="100%"
                  sizes={{ margin: '0 17px' }}
                  onClick={() => toggleSucceededRescore()}
                >
                  {t('home.yes')}
                </Button>
              </ButtonWrapper>
            )}
            {succeededRescore && (
              <>
                <AfterCardContainer>
                  <AfterCard>
                    <InputField
                      placeholder={t('home.email')}
                      value={email}
                      name="email"
                      isValid={emailValidation.isValid}
                      onChange={setEmail}
                      onKeyDown={handlePressEnter}
                    />
                    <InputField
                      placeholder={t('home.emailAgain')}
                      value={emailAgain}
                      name="emailAgain"
                      errorMessage={emailAgainValidation.message}
                      isValid={emailAgainValidation.isValid}
                      onChange={setEmailAgain}
                      onKeyDown={handlePressEnter}
                    />
                  </AfterCard>
                  <AfterCardHint>{t('home.emailDescription')}</AfterCardHint>
                </AfterCardContainer>
                <BottomCard>
                  <CheckBoxContrainer>
                    <CheckBox
                      testID="TEST_CHECKBOX"
                      checked={confirmed}
                      onChange={() => toggleConfirmed()}
                    >
                      <Text>
                        {t('reg.accept_1')}{' '}
                        <TextLink testID="TEST_TEXTLINK" to="/terms.pdf" target="_href">
                          {t('reg.accept_2')}
                        </TextLink>{' '}
                        {t('reg.accept_3')}
                      </Text>
                    </CheckBox>
                  </CheckBoxContrainer>
                  <ButtonWrapperWithError>
                    <Button
                      maxWidth="308px"
                      width="100%"
                      testID="TEST_BUTTON_PAYMENT"
                      disabled={
                        !emailAgainValidation.isValid || !emailValidation.isValid || !confirmed
                      }
                      changeByDisable
                      onClick={formHandler}
                    >
                      {t('home.goToPay')}
                    </Button>
                    {!confirmed && (
                      <ErrorTextTransformed>{t('home.confirmError')}</ErrorTextTransformed>
                    )}
                  </ButtonWrapperWithError>
                </BottomCard>
              </>
            )}
          </WrrapperBox>
        </WrapperContainer>
      </Wrapper>
    </Main>
  );
};

RescoreResponse.propTypes = {
  entity: PropTypes.object,
  emailValidation: PropTypes.object,
  emailAgainValidation: PropTypes.object,
  email: PropTypes.string,
  emailAgain: PropTypes.string,
  handlePressEnter: PropTypes.func,
  isBusy: PropTypes.bool.isRequired,
  succeededRescore: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  setEmail: PropTypes.func.isRequired,
  setEmailAgain: PropTypes.func.isRequired,
  formHandler: PropTypes.func.isRequired,
  toggleSucceededRescore: PropTypes.func.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
};

RescoreResponse.defaultProps = {
  entity: undefined,
  email: '',
  emailAgain: '',
  emailValidation: {},
  emailAgainValidation: {},
  handlePressEnter: () => {},
};

const enhance = compose(
  inject(storeNames.EntityStore, storeNames.ScoringStore),
  observer,
  withProps(({ EntityStore }) => ({
    isBusy: EntityStore.isBusy,
  })),
  withBoolState('succeededRescore', false),
  withBoolState('confirmed', false),
  withState('entity', 'setEntity', undefined),
  withHandlers(() => ({
    validateEmails: () => ({ email, emailAgain, value }) => {
      const filteredValue = toLowerCaseAndTrim(value);
      const emailIsValid = checkIsEmailValid(filteredValue);
      const message =
        hasValue(emailAgain) &&
        getEmailEqualMessage({
          email,
          emailAgain,
        });
      return { isValid: !message && emailIsValid, message };
    },
  })),
  withStateHandlers(
    ({ validateEmails }) => ({
      email: undefined,
      emailAgain: undefined,
      emailValidation: { isValid: true },
      emailAgainValidation: { isValid: true },
      validateEmails,
    }),
    {
      setEmail: ({ emailAgain, validateEmails }) => value => {
        const filteredValue = toLowerCaseAndTrim(value);
        const filteredEmailAgain = toLowerCaseAndTrim(emailAgain);
        const { message, isValid } = validateEmails({
          email: filteredValue,
          emailAgain: filteredEmailAgain,
          value: filteredValue,
        });
        if (isValid) {
          return {
            email: filteredValue,
            emailAgain: filteredEmailAgain,
            emailValidation: { isValid, message },
            emailAgainValidation: { isValid, message },
          };
        }
        return { email: filteredValue, emailValidation: { isValid, message } };
      },
      setEmailAgain: ({ email, validateEmails }) => value => {
        const filteredValue = toLowerCaseAndTrim(value);
        const filteredEmail = toLowerCaseAndTrim(email);
        const { message, isValid } = validateEmails({
          emailAgain: filteredValue,
          email: filteredEmail,
          value: filteredValue,
        });
        if (isValid) {
          return {
            emailAgain: filteredValue,
            email: filteredEmail,
            emailValidation: { isValid, message },
            emailAgainValidation: { isValid, message },
          };
        }
        return { emailAgain: filteredValue, emailAgainValidation: { isValid, message } };
      },
    },
  ),
  withHandlers(({ ScoringStore, EntityStore }) => ({
    formHandler: ({
      email,
      emailAgain,
      setEmail,
      setEmailAgain,
      emailAgainValidation,
      emailValidation,
      confirmed,
    }) => async () => {
      if (
        !emailAgainValidation.isValid ||
        !emailValidation.isValid ||
        !confirmed ||
        ScoringStore.isBusy
      )
        return false;
      const filteredEmail = toLowerCaseAndTrim(email);
      const filteredEmailAgain = toLowerCaseAndTrim(emailAgain);
      if (!hasValue(filteredEmail) || !hasValue(filteredEmailAgain)) {
        setEmail(undefined);
        setEmailAgain(undefined);
        return false;
      }
      const { entity } = EntityStore;
      await ScoringStore.successPayment({ email: filteredEmail, psrn: entity.ogrn });
      if (!ScoringStore.confirmationUrl) {
        return false;
      }
      window.location.replace(ScoringStore.confirmationUrl);
      return true;
    },
  })),
  withHandlePressEnter,
  lifecycle({
    async componentDidMount() {
      const { EntityStore, setEntity } = this.props;
      const { entity } = EntityStore;
      if (entity) setEntity(entity);
      if (!entity) {
        const url = getUrlObject();
        const id = url.searchParams.get('data');
        if (id) {
          const result = await EntityStore.fetchEntity(id);
          const { errors } = EntityStore;
          if (errors && errors.length) {
            history.push('/');
          } else {
            setEntity(result);
          }
        } else {
          history.push('/');
        }
      }
    },
  }),
);

export default enhance(RescoreResponse);
