import api from "../api";
import { AbstractListStore, decorateAbstractListStore } from "./base";

const PAGE_SIZE = 50

export default class CustomListPersonsStore extends AbstractListStore {
    reset() {
        api.customLists.break()
        api.customLists.reset()
        super.reset()
    }

    load({ listId, page }) {
        return api.customLists.findPersons({listId, size: PAGE_SIZE, page})
    }

    areItemsEqual(a, b) {
        return (a.ogrnip !== null && a.ogrnip === b.ogrnip) || (a.inn !== null && a.inn === b.inn)
    }

    async remove({ listId, inn, ogrnip }) {
        await api.customLists.deletePerson(listId, { inn, ogrnip })
        this.list = this.list.filter(x => x.inn !== inn && x.ogrnip !== ogrnip)
    }
}

decorateAbstractListStore(CustomListPersonsStore)