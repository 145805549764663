import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose";
import stores from "../../../stores";
import { ButtonDelete, ButtonEdit, ButtonSearch, TableContainer, Tbody, Td, TdButton, Th, Thead, Tr, TrTop } from "./TableStyled";
import { inject, observer } from "mobx-react";
import storeNames from "../../../stores/storeNames";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { NavLink } from 'react-router-dom';
import CustomListPersonsStore from '../../../stores/CustomListPersons';
import CustomListEntitiesStore from '../../../stores/CustomListEntities';

const Table = ({lists, hasMoreItems, fetchCustomsList, removeList, onAddEntries}) => {
    const { t } = useTranslation();
    return (
        <>
          <InfiniteScroll
            initialLoad={true}
            loadMore={() => fetchCustomsList()}
            hasMore={hasMoreItems}
            style={{ overflow: 'auto' }}
          >
            <TableContainer>
                <Thead>
                <TrTop>
                    <Th>#</Th>
                    <Th>{t('customLists.title')}</Th>
                    <Th>{t('customLists.isRiskList')}</Th>
                    <Th>{t('customLists.entitiesСount')}</Th>
                    <Th>{t('customLists.personsСount')}</Th> 
                    <Th>{t('customLists.createdAt')}</Th>
                    <Th>{t('customLists.updatedAt')}</Th>
                    <Th colSpan={3}></Th>
                </TrTop>
                </Thead>
                <Tbody>
                {lists && lists.map((item, i) => {
                return (
                    <Tr key={item.id}>
                      <Td>{i + 1}</Td>
                      <Td>{item.title}</Td>
                      <Td>{item.isRiskList ? (<strong>{t('form.Yes')}</strong>) : t('form.No')}</Td>
                      <Td>{item.entitiesCount || 0}</Td>
                      <Td>{item.personsCount || 0}</Td>
                      <Td>{moment(item.createdAt).format('DD.MM.YYYY - HH:mm:ss')}</Td>
                      <Td>{moment(item.updatedAt).format('DD.MM.YYYY - HH:mm:ss')}</Td>
                      <TdButton>
                          <Tooltip title={t('customLists.tooltipViewEntries')} placement="top" arrow>
                            <NavLink to={`/custom-lists/${item.id}`}>
                              <ButtonSearch>view</ButtonSearch>
                            </NavLink>
                          </Tooltip>
                      </TdButton>
                      <TdButton>
                          <Tooltip title={t('customLists.tooltipAddEntries')} placement="top" arrow>
                            <ButtonEdit
                              onClick={e => {
                                e.preventDefault();
                                onAddEntries(item)
                              }}
                            >
                                edit
                            </ButtonEdit>
                          </Tooltip>
                      </TdButton>
                      <TdButton>
                        <Tooltip title={t('customLists.tooltipDeleteList')} placement="top" arrow>
                          <ButtonDelete onClick={e => removeList(e, item.id)}>
                              delete
                          </ButtonDelete>
                        </Tooltip>
                      </TdButton>
                    </Tr>
                )
                })}
                </Tbody>
            </TableContainer>
          </InfiniteScroll>
        </>
    )
}

Table.propTypes = {
    lists: PropTypes.array,
    hasMoreItems: PropTypes.bool.isRequired,
    fetchCustomsList: PropTypes.func.isRequired
}

const enhance = compose(
    inject(storeNames.CustomListsStore, storeNames.DialogStore),
    observer,
    withProps(({CustomListsStore, DialogStore}) => ({
      lists: CustomListsStore.lists,
      hasMoreItems: CustomListsStore.hasMoreItems,
      loader: DialogStore.loader,
    })),
    withState('offset', 'setOffset', 0),
    withHandlers(() => ({
      fetchCustomsList: ({offset, setOffset, loader}) => async () => {
        try {
          if (offset === 0) {
            loader(true)
          }
          await stores.CustomListsStore.fetchCustomLists({ size: 50, offset })
          setOffset(stores.CustomListsStore.lists.length)
        } finally {
          loader(false)
        }
          
      },
      removeList: ({offset, setOffset}) => async (e, id) => {
        e.preventDefault();
        const deleted = await stores.CustomListsStore.removeList(id);
        if (deleted) {
          setOffset(Math.max(0, offset - 1));
        }
      }
    })),
    lifecycle({
      async componentWillUnmount() {
        stores[CustomListPersonsStore.name].reset()
        stores[CustomListEntitiesStore.name].reset()
      }
    })
);

export default enhance(Table)