import React from 'react';
import { inject, observer } from "mobx-react";
import { useTranslation, withTranslation } from "react-i18next";
import { compose, withHandlers, withProps, withState } from "recompose";
import Popup from "../../../components/Popup/Popup";
import { ButtonWrapper, FormWrapper, FullWidthDiv, Result, ResultPlaceholder, TextArea, Title, Wrapper } from "./PopupsStyled";
import storeNames from '../../../stores/storeNames';
import { COLOR_ACTION_BLUE } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import stores from '../../../stores';

const AddEntriesPopup = ({onClosePopup, onSubmit, isAddingEntries, entries, setEntries, result}) => {
    const { t } = useTranslation();
    return (
      <Popup
        width="600px"
        boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
        borderRadius="4px"
        closePopup={onClosePopup}
      >
        <Wrapper>
          <Title as="h2">{t('customLists.popupAddEntriesTitle')}</Title>
          {/* <div>{selectedItem.title}</div> */}
          <FormWrapper onSubmit={onSubmit}>
            <FullWidthDiv>
              <TextArea
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="3"
                placeholder={t('customLists.popupAddEntriesPlaceholder')}
                name="entries"
                value={entries}
                onChange={e => setEntries(e.target.value)}
              />
            </FullWidthDiv>
            { result 
              ? (<Result>
                  {t('customLists.popupAddEntriesResultEntities')}: <strong>{result.entities}</strong>
                  &nbsp;&nbsp;&nbsp;
                  {t('customLists.popupAddEntriesResultPersons')}: <strong>{result.persons}</strong>
                </Result>) 
              : (<ResultPlaceholder>&nbsp;</ResultPlaceholder>)}
            <ButtonWrapper>
              <Button
                testID="TEST_BUTTON_ADD"
                width="303px"
                type="submit"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="7"
                sizes={{ margin: '0 20px' }}
                disabled={isAddingEntries}
              >
                {t('form.add')}
              </Button>
              <Button
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="6"
                testID="TEST_BUTTON_CANCEL"
                width="303px"
                type="submit"
                textColor={COLOR_ACTION_BLUE}
                boxShadow="none"
                backgroundColor="#fff"
                sizes={{ margin: '0 20px' }}
                disabled={isAddingEntries}
                onClick={() => onClosePopup()}
              >
                {t('form.cancel')}
              </Button>
            </ButtonWrapper>
          </FormWrapper>
        </Wrapper>
      </Popup>
    )
}

const enchance = compose(
    inject(storeNames.CustomListsStore),
    observer,
    withTranslation(),
    withState('totalAddedEntries', 'setTotalAddedEntries', 0),
    withState('entries', 'setEntries', ''),
    withState('result', 'setResult', null),
    withProps(({CustomListsStore}) => ({
        selectedItem: CustomListsStore.selectedItem,
        isAddingEntries: CustomListsStore.isAddingEntries
    })),
    withHandlers({
        onSubmit: ({selectedItem, entries, setResult, setTotalAddedEntries}) => async (e) => {
          e.preventDefault()
          setResult(null)
          const data = {
            entries: entries.split('\n').map(x => x.trim())
          }
          const result = await stores.CustomListsStore.addEntries(selectedItem.id, data)
          setResult(result)
          setTotalAddedEntries(result.entities + result.persons)
        },
        onClosePopup: ({closePopup, totalAddedEntries}) => {
          if (totalAddedEntries > 0) {
            window.location.reload()
          } else {
            closePopup()
          }
        }
    })
)

export default enchance(AddEntriesPopup)