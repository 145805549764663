import API from './base';
import { createQueryString } from '../helpers/url';
import { getToken } from '../helpers';

export default class CustomListsApi extends API {
  /**
   *
   * @param data {{size: number, page: number}}
   * @param token {string}
   */
  find({page, size}) {
    return this.get(`api/custom-lists?${createQueryString({size, page})}`, getToken());
  }

  findOne(listId) {
    return this.get(`api/custom-lists/${listId}`, getToken());
  }

  findPersons({listId, page, size}) {
    return this.get(`api/custom-lists/${listId}/person?${createQueryString({size, page})}`, getToken());
  }

  findEntities({listId, page, size}) {
    return this.get(`api/custom-lists/${listId}/entity?${createQueryString({size, page})}`, getToken());
  }

  deleteById(id) {
    return this.delete(`api/custom-lists/${id}`, {}, getToken());
  }

  addList(data) {
    return this.post(`api/custom-lists`, data, getToken())
  }

  addEntries(id, data) {
    return this.post(`api/custom-lists/${id}/entries`, data, getToken())
  }

  deleteEntity(id, ogrn) {
    return this.delete(`api/custom-lists/${id}/entity`, { ogrn: [ogrn] }, getToken());
  }

  deletePerson(id, { ogrnip, inn }) {
    return this.delete(`api/custom-lists/${id}/person`, { ogrnip: [ogrnip], inn: [inn] }, getToken());
  }
}
