import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { compose, withProps, withHandlers, withState } from 'recompose';
import ReCAPTCHA from 'react-google-recaptcha';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withBoolState } from '../../utils/hocs';
import { withSurnameState } from '../../utils/FormHocs';
import Organization from './RegistrationUsers/Organization';
import { getOrigin } from '../../helpers/url';
import IndividualEntrepreneur from './RegistrationUsers/IndividualEntrepreneur';
import Individual from './RegistrationUsers/Individual';
import storeNames from '../../stores/storeNames';
import {
  Main,
  Wrapper,
  Title,
  RegistrationForm,
  RadioButtons,
  RadioButton,
  RegistrationContent,
  Button,
  RadioButtonWrap,
  Captcha,
  ParagraphErrorCaptcha,
} from './RegistrationStyled';
import {
  CUSTOMER_LEGAL_ENTITY,
  CUSTOMER_SOLE_PROPRIETOR,
  CUSTOMER_INDIVIDUAL,
} from '../../constants/customerTypes';
import logger from '../../logger';
import { setToken } from '../../helpers';
import history from '../../history';
import { TEST_SIGNUP, TEST_BUTTON_SIGNUP } from '../../constants/testids';
import { toLowerCaseAndTrim } from '../../utils/validators';

const Registration = ({
  setCheckedRadioButton,
  checkedRadioButton,
  formHandler,
  checkedCheckBox,
  confirmed,
  setIsValidIndividual,
  setIsValidIndividualEntrepreneur,
  isValidOrganization,
  setIsValidOrganization,
  isValidIndividual,
  isValidIndividualEntrepreneur,
  toggleConfirmed,
  isBusy,
  newPasswordError,
  confirmPasswordError,
  onChangeCaptcha,
  captchaError,
}) => {
  const { t } = useTranslation();
  return (
    <Main>
      <Wrapper>
        <Title as="h2">{t('reg.registration')}</Title>
        <RegistrationForm onSubmit={formHandler}>
          <RadioButtons>
            <RadioButtonWrap>
              <RadioButton
                testID={`${TEST_SIGNUP}${CUSTOMER_LEGAL_ENTITY}`}
                name="registration"
                value={CUSTOMER_LEGAL_ENTITY}
                onClick={setCheckedRadioButton}
                checked={checkedRadioButton === CUSTOMER_LEGAL_ENTITY}
              >
                {t('reg.organ')}
              </RadioButton>
            </RadioButtonWrap>
            <RadioButtonWrap>
              <RadioButton
                testID={`${TEST_SIGNUP}${CUSTOMER_SOLE_PROPRIETOR}`}
                name="registration"
                value={CUSTOMER_SOLE_PROPRIETOR}
                onClick={setCheckedRadioButton}
                checked={checkedRadioButton === CUSTOMER_SOLE_PROPRIETOR}
              >
                {t('reg.ie')}
              </RadioButton>
            </RadioButtonWrap>
            <RadioButton
              testID={`${TEST_SIGNUP}${CUSTOMER_INDIVIDUAL}`}
              name="registration"
              value={CUSTOMER_INDIVIDUAL}
              checked={checkedRadioButton === CUSTOMER_INDIVIDUAL}
              onClick={setCheckedRadioButton}
            >
              {t('reg.np')}
            </RadioButton>
          </RadioButtons>
          <RegistrationContent>
            {checkedRadioButton === CUSTOMER_LEGAL_ENTITY ? (
              <Organization
                newPasswordError={newPasswordError}
                confirmPasswordError={confirmPasswordError}
                isValidOrganization={isValidOrganization}
                setIsValidOrganization={setIsValidOrganization}
                checkedCheckBox={checkedCheckBox}
                confirmed={confirmed}
                toggleConfirmed={toggleConfirmed}
              />
            ) : null}
            {checkedRadioButton === CUSTOMER_SOLE_PROPRIETOR ? (
              <IndividualEntrepreneur
                isValidIndividualEntrepreneur={isValidIndividualEntrepreneur}
                setIsValidIndividualEntrepreneur={setIsValidIndividualEntrepreneur}
                newPasswordError={newPasswordError}
                confirmPasswordError={confirmPasswordError}
                checkedCheckBox={checkedCheckBox}
                confirmed={confirmed}
                toggleConfirmed={toggleConfirmed}
              />
            ) : null}
            {checkedRadioButton === CUSTOMER_INDIVIDUAL ? (
              <Individual
                setIsValidIndividual={setIsValidIndividual}
                isValidIndividual={isValidIndividual}
                newPasswordError={newPasswordError}
                confirmPasswordError={confirmPasswordError}
                checkedCheckBox={checkedCheckBox}
                confirmed={confirmed}
                toggleConfirmed={toggleConfirmed}
              />
            ) : null}
          </RegistrationContent>
          <Captcha>
            <ReCAPTCHA
              sitekey="6LcNdmMcAAAAAL5QoiSA85pjCqgs_rxhkkWyxD-X"
              onChange={onChangeCaptcha}
            />
            <ParagraphErrorCaptcha>{t(captchaError)}</ParagraphErrorCaptcha>
          </Captcha>
          <Button
            maxWidth="366px"
            type="submit"
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="99"
            data-test-id={TEST_BUTTON_SIGNUP}
            disabled={
              isBusy ||
              !confirmed ||
              (!isValidOrganization && checkedRadioButton === CUSTOMER_LEGAL_ENTITY) ||
              (!isValidIndividualEntrepreneur && checkedRadioButton === CUSTOMER_SOLE_PROPRIETOR) ||
              (!isValidIndividual && checkedRadioButton === CUSTOMER_INDIVIDUAL)
            }
          >
            {t('reg.checkin')}
          </Button>
        </RegistrationForm>
      </Wrapper>
    </Main>
  );
};

Registration.propTypes = {
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  isBusy: PropTypes.bool.isRequired,
  captchaError: PropTypes.string,
  isValidIndividual: PropTypes.bool.isRequired,
  isValidOrganization: PropTypes.bool.isRequired,
  isValidIndividualEntrepreneur: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  setIsValidIndividual: PropTypes.func.isRequired,
  setIsValidOrganization: PropTypes.func.isRequired,
  onChangeCaptcha: PropTypes.func,
  setIsValidIndividualEntrepreneur: PropTypes.func.isRequired,
  checkedRadioButton: PropTypes.string,
  checkedCheckBox: PropTypes.bool,
  setCheckedRadioButton: PropTypes.func,
  formHandler: PropTypes.func,
};

Registration.defaultProps = {
  newPasswordError: '',
  captchaError: '',
  confirmPasswordError: '',
  checkedRadioButton: CUSTOMER_LEGAL_ENTITY,
  checkedCheckBox: false,
  setCheckedRadioButton: () => {},
  onChangeCaptcha: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  inject(storeNames.RegistrationStore, storeNames.CustomerStore),
  inject(storeNames.LanguageStore),
  observer,
  withTranslation(),
  withSurnameState,
  withProps(({ RegistrationStore, CustomerStore }) => ({
    signUpForm: RegistrationStore.signUpForm,
    signUp: RegistrationStore.signUp,
    createUser: CustomerStore.createUser,
    isBusy: CustomerStore.isBusy,
  })),
  withBoolState('isValidIndividual', false),
  withBoolState('isValidIndividualEntrepreneur', false),
  withBoolState('isValidOrganization', false),
  withBoolState('confirmed', false),
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withState('checkedRadioButton', 'setCheckedRadioButton', CUSTOMER_LEGAL_ENTITY),
  withState('checkedCheckBox', 'setCheckBox', true),
  withState('captchaValue', 'setCaptchaValue', false),
  withState('captchaError', 'setCaptchaError', ''),
  withHandlers(() => ({
    onSignup: ({ createUser, t, LanguageStore, i18n }) => async userData => {
      try {
        const token = await createUser(userData);
        if (token) {
          setToken(token);
          await LanguageStore.syncLocale(i18n.language);
          history.push(
            `/status?status=success&message=${t('reg.youRegisteredSuccessful')}&buttonText=${t(
              'reg.toProfile',
            )}&description=${t('reg.loginSentToEmail')}&actionUrl=${getOrigin()}/profile`,
          );
        }
      } catch (ex) {
        logger.error(ex);
      }
    },
    onChangeCaptcha: ({ setCaptchaValue }) => () => {
      setCaptchaValue(true);
    },
  })),
  withHandlers(() => ({
    formHandler: ({
      RegistrationStore,
      checkedRadioButton,
      confirmed,
      setCheckBox,
      onSignup,
      setNewPasswordError,
      setConfirmPasswordError,
      captchaValue,
      setCaptchaError,
    }) => async e => {
      e.preventDefault();
      if (confirmed === false) {
        setCheckBox(false);
      } else {
        setCheckBox(true);
      }

      // Captcha Валидация //
      let captchaValueValid = false;
      if (!captchaValue) {
        setCaptchaError('reg.robotCheck');
        captchaValueValid = false;
      } else {
        setCaptchaError('');
        captchaValueValid = true;
      }
      // Captcha Валидация //

      // ВАЛИДАЦИЯ ПАРОЛЯ //
      let passwordIsValid = true;
      const regexValid = /^[a-zA-Z0-9]+$/;
      if (!RegistrationStore.signUpForm.newPassword) {
        passwordIsValid = false;
        setNewPasswordError('reg.inputPass');
      } else {
        setNewPasswordError('');
        if (RegistrationStore.signUpForm.newPassword.length < 8) {
          passwordIsValid = false;
          setNewPasswordError('reg.passNotLess');
        } else {
          setNewPasswordError('');
          if (!regexValid.test(RegistrationStore.signUpForm.newPassword)) {
            passwordIsValid = false;
            setNewPasswordError('reg.passTerm');
            setConfirmPasswordError('');
          } else {
            setNewPasswordError('');
            if (
              RegistrationStore.signUpForm.newPassword !==
              RegistrationStore.signUpForm.confirmPassword
            ) {
              setConfirmPasswordError('reg.passNotSame');
              passwordIsValid = false;
            } else {
              setConfirmPasswordError('');
              passwordIsValid = true;
            }
          }
        }
      }
      // ВАЛИДАЦИЯ ПАРОЛЯ //

      let signUpData = {};

      if (
        checkedRadioButton === CUSTOMER_LEGAL_ENTITY &&
        confirmed === true &&
        passwordIsValid === true &&
        captchaValueValid
      ) {
        signUpData = {
          type: checkedRadioButton,
          ogrn: RegistrationStore.signUpForm.ogrn,
          inn: RegistrationStore.signUpForm.inn,
          full_name: RegistrationStore.signUpForm.ogrName,
          contact_person_sname: RegistrationStore.signUpForm.surname,
          contact_person_fname: RegistrationStore.signUpForm.name,
          sname: RegistrationStore.signUpForm.surname,
          fname: RegistrationStore.signUpForm.name,
          email: toLowerCaseAndTrim(RegistrationStore.signUpForm.email),
          post_address: RegistrationStore.signUpForm.postAddress,
          bank_name: RegistrationStore.signUpForm.bank,
          password: RegistrationStore.signUpForm.newPassword,
          login: toLowerCaseAndTrim(RegistrationStore.signUpForm.login),
        };
        if (RegistrationStore.signUpForm.phone) {
          signUpData.phone = RegistrationStore.signUpForm.phone;
        }
        await onSignup(signUpData);
      }
      if (
        checkedRadioButton === CUSTOMER_SOLE_PROPRIETOR &&
        confirmed === true &&
        passwordIsValid === true &&
        captchaValueValid
      ) {
        signUpData = {
          type: checkedRadioButton,
          ogrn: RegistrationStore.signUpForm.ogrnip,
          inn: RegistrationStore.signUpForm.inn,
          contact_person_sname: RegistrationStore.signUpForm.surname,
          contact_person_fname: RegistrationStore.signUpForm.name,
          contact_person_mname: RegistrationStore.signUpForm.patronymic,
          mname: RegistrationStore.signUpForm.patronymic,
          sname: RegistrationStore.signUpForm.surname,
          fname: RegistrationStore.signUpForm.name,
          full_name: `${RegistrationStore.signUpForm.surname} ${RegistrationStore.signUpForm.name}`,
          email: toLowerCaseAndTrim(RegistrationStore.signUpForm.email),
          post_address: RegistrationStore.signUpForm.postAddress,
          password: RegistrationStore.signUpForm.newPassword,
          login: toLowerCaseAndTrim(RegistrationStore.signUpForm.login),
        };
        if (RegistrationStore.signUpForm.phone) {
          signUpData.phone = RegistrationStore.signUpForm.phone;
        }
        await onSignup(signUpData);
      }
      if (
        checkedRadioButton === CUSTOMER_INDIVIDUAL &&
        confirmed === true &&
        passwordIsValid === true &&
        captchaValueValid
      ) {
        signUpData = {
          type: checkedRadioButton,
          sname: RegistrationStore.signUpForm.surname,
          contact_person_sname: RegistrationStore.signUpForm.surname,
          contact_person_fname: RegistrationStore.signUpForm.name,
          contact_person_mname: RegistrationStore.signUpForm.patronymic,
          fname: RegistrationStore.signUpForm.name,
          mname: RegistrationStore.signUpForm.patronymic,
          full_name: `${RegistrationStore.signUpForm.surname} ${RegistrationStore.signUpForm.name}`,
          email: toLowerCaseAndTrim(RegistrationStore.signUpForm.email),
          post_address: RegistrationStore.signUpForm.postAddress,
          birth_date: moment(RegistrationStore.signUpForm.birth_date).format('YYYY-MM-DD'),
          password: RegistrationStore.signUpForm.newPassword,
          login: toLowerCaseAndTrim(RegistrationStore.signUpForm.email),
        };
        if (RegistrationStore.signUpForm.phone) {
          signUpData.phone = RegistrationStore.signUpForm.phone;
        }
        await onSignup(signUpData);
      }
    },
  })),
);

export default enhancer(Registration);
