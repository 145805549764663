import { inject } from 'mobx-react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';
import { compose, lifecycle, withProps, withState } from "recompose";
import storeNames from '../../stores/storeNames';
import { withBoolState } from '../../utils/hocs';
import { Tabs, TabsItem } from '../Balance/BalanceStyled';
import AddEntriesPopup from '../CustomLists/Popups/AddEntriesPopup';
import { Button, Title, Top, Wrapper } from "./CustomListsDetailsStyled";
import EntitiesTable from './EntitiesTable/EntitiesTable';
import PersonsTable from './PersonsTable/PersonsTable';

const CustomListsDetails = ({listId, activeTab, setActiveTab, addEntriesPopup, toggleAddEntriesPopup, listDetails}) => {
  const { t } = useTranslation();
  if (!listDetails) {
    return null
  }
  return (
    <>
      <Wrapper>
        <Top>
          <Title as="h2">{t('customLists.detailsPageTitle')} {listDetails.title}</Title>
          <Button onClick={() => toggleAddEntriesPopup()}>
            {t('customLists.tooltipAddEntries')}
          </Button>
        </Top>
        <Tabs>
          <TabsItem
            className={activeTab === 0 ? 'active' : null}
            onClick={() => setActiveTab(0)}
          >
            {t('customLists.detailsTabEntities')}
          </TabsItem>
          <TabsItem
            className={activeTab === 1 ? 'active' : null}
            onClick={() => setActiveTab(1)}
          >
            {t('customLists.detailsTabPersons')}
          </TabsItem>
        </Tabs>
        {activeTab === 0 ? (
            <EntitiesTable listId={listId} />
        ) : null}
        {activeTab === 1 ? (
            <PersonsTable listId={listId} />
        ) : null}
      </Wrapper>
      {addEntriesPopup ? (
        <AddEntriesPopup closePopup={toggleAddEntriesPopup} />
      ) : null}
    </>
  )
}

const enhance = compose(
  inject(storeNames.DialogStore, storeNames.CustomListsStore),
  withState('listDetails', 'setListDetails', 0),
  withProps(({ DialogStore }) => ({
    listId: useParams().listId,
    loader: DialogStore.loader,
  })),
  lifecycle({
    async componentDidMount() {
      const { CustomListsStore, listId, setListDetails, loader } = this.props;
      try {
        loader(true)
        setListDetails(await CustomListsStore.fetchOne({ listId }))
      } finally {
        loader(false)
      }
    }
  }),
  withBoolState('addEntriesPopup', false),
  withState('activeTab', 'setActiveTab', 0),
);

export default enhance(CustomListsDetails);